/* Modal used to manage a store service items
Service items will contain: Title, description, durationInMinutes, price, archived
Users can add, remove, and edit service items from this screen.
 */

import React, { useState, useEffect } from 'react';
import { Typography, Button } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import ServiceItem from './ServiceItem';
import useServiceItemData from './hooks/useServiceItemData';
import Card from '@material-ui/core/Card';
import Alert from '@material-ui/lab/Alert';
import Divider from '@material-ui/core/Divider';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ServiceItemsManager({
  className,
  name,
  storeId,
  openModalOveride,
  setOpenModalOveride,
  setParentSvcItemCount,
}) {
  const [open, setOpen] = useState(openModalOveride || false);
  const { serviceItems, loading, error, actions } = useServiceItemData(storeId);

  useEffect(() => {
    setOpen(openModalOveride);
  }, [openModalOveride]);

  useEffect(() => {
    setParentSvcItemCount && setParentSvcItemCount(serviceItems.length);
    //eslint-disable-next-line
  }, [serviceItems]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpenModalOveride && setOpenModalOveride(false);
    setOpen(false);
  };

  return (
    <>
      {storeId !== 'NEW' && (
        <Button variant="contained" color="primary" className={className} onClick={handleClickOpen}>
          {`Manage Your Services`}
        </Button>
      )}
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        fullScreen={true}
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        maxWidth="xl"
        style={{ zIndex: 2000 }}
      >
        <DialogTitle id="alert-dialog-slide-title">{`Manage Services for ${name}`}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <Typography variant="p">Here you can manage the services you offer at your store:</Typography>
          </DialogContentText>

          {loading && (
            <Alert variant="outlined" severity="error" style={{ margin: '16px 0px' }}>
              Loading...
            </Alert>
          )}
          {error && (
            <Alert variant="outlined" severity="error" style={{ margin: '16px 0px' }}>
              {error}
            </Alert>
          )}
          <Divider />
          {/* Because save in within each item, each items should ahve error state - need to refactor */}

          {/* Iterate services offered,  */}
          {loading === false &&
            error === null &&
            (serviceItems.length > 0 ? (
              serviceItems.map((serviceItem) => {
                return <ServiceItem key={serviceItem.id || new Date()} service={serviceItem} actions={actions} />;
              })
            ) : (
              <Alert variant="outlined" severity="info" style={{ margin: '16px 0px' }}>
                You don't have any services yet
              </Alert>
            ))}
          <Card
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              margin: '16px',
              padding: '16px',
              flexWrap: 'wrap',
            }}
          >
            {/* Button to add service */}
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                actions.addServiceItem();
              }}
              disabled={loading || error || serviceItems.some((serviceItem) => !serviceItem.id)}
            >
              {`+ Add a Service`}
            </Button>
          </Card>
        </DialogContent>
        {/* Center contents of dialog actions */}
        <DialogActions
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {/* <Button onClick={handleClose} color="primary" style={{ textShadow: '1px 1px 1px #ff9780' }}>
            Close
          </Button> */}
          <Button variant="contained" color="primary" onClick={handleClose} style={{ width: '25vw' }}>
            {`Close`}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
