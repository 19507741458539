import React from 'react';
import { determineMobileServiceType, formatPhoneNumber, handleImage } from '../../utils/helpers'; //  handleImage(data.current._embedded.file?.url, { placeholderType: 'storeLogo' }
// import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { Link, Tooltip, makeStyles } from '@material-ui/core';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import InstagramIcon from '@material-ui/icons/Instagram';
import ShareIcon from '@material-ui/icons/Share';
import FacebookIcon from '@material-ui/icons/Facebook';
import PublicIcon from '@material-ui/icons/Public';
// import TwitterIcon from '@material-ui/icons/Twitter'; //Need to update to X
// import SnapChatIcon from './SnapChatIcon';
// import MapIcon from '../../assets/icons/location-white.svg';
// import DirectionsIcon from '@material-ui/icons/Directions';
import MapIcon from '@material-ui/icons/Map';
//Need snap chat

const useStyles = makeStyles((theme) => ({
  businessInfoRoot: {
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'center',
    color: theme.palette.common.characters,
    width: '75%',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column-reverse',
      width: '100%',
    },
  },
  businessHeroImgContainer: {
    maxHeight: '500px',

    width: '100%',
    '& > img': {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
  },
  businessDetailsContainer: {
    display: 'flex',
    padding: theme.spacing(2, 2, 2, 0), //No padding on left
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'row',
      alignItems: 'flex-start',
      textAlign: 'center',
      padding: '4px',
    },
  },
  logoImg: {
    width: '200px',
    height: '200px',
    borderRadius: '5%',
    margin: theme.spacing(0, 2, 0, 0),
    [theme.breakpoints.down('sm')]: {
      margin: 0,
      width: '125px',
      height: '125px',
    },
  },
  businessDetails: {
    color: theme.palette.common.font,
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    '& > *': {
      margin: theme.spacing(0.5, 0),
    },
  },
  businessName: {
    fontSize: '4rem',
    color: theme.palette.common.font,
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.5rem',
      fontWeight: 'bold',
    },
  },
  contactInfo: {
    display: 'flex',
    flexDirection: 'row',
    '& > *': {
      margin: theme.spacing(0, 0.5),
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      gap: '4px',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  hideInMobile: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  mobileServiceDetails: {
    display: 'flex',
    gap: '8px',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
  detailsActionButtons: {
    //Space between buttons
    '& > *': {
      margin: theme.spacing(0, 2, 0, 0),
    },
  },
  infoLinks: {
    display: 'flex',
    color: theme.palette.common.font,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
      color: theme.palette.common.font,
    },
  },
  socialIcon: {
    color: theme.palette.common.characters,
    // stroke: theme.palette.common.characters,
    fill: theme.palette.common.characters,
    width: '2rem',
    height: '2rem',
    borderRadius: '50%',

    '&:hover': {
      color: theme.palette.common.characters,
      // stroke: theme.palette.common.characters,
      fill: theme.palette.common.characters,
      //Add shadow and underline
      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
      textDecoration: 'underline',
    },
  },
  charactersColor: {
    color: theme.palette.common.characters,
  },
}));

export default function BusinessInfo({ data }) {
  const classes = useStyles();
  const mobileServiceType = data && determineMobileServiceType(data);

  const businessHeroImage = handleImage(data?._embedded.bannerImageFile?.url, {
    placeholderType: 'storeBanner',
  });
  const businessLogo = handleImage(data?._embedded.file?.url, { placeholderType: 'storeLogo' });
  const parsedRegion = `${data?.address.city}, ${data?.address.province}`;
  const businessAddress = (
    <span className="address">
      <Typography>{`${data?.address.street}`}</Typography>
      <Typography>{parsedRegion}</Typography>

      {data?.mobileService && (
        <div className={classes.mobileServiceDetails}>
          <DriveEtaIcon className={classes.charactersColor} />
          <Typography>
            {mobileServiceType === 'mobileOnly' ? `Only mobile service` : `We offer mobile services`}
          </Typography>
        </div>
      )}
    </span>
  );

  const contactInfoFields = {
    tel: data?.phone,
    mail: data?.email,
    http: data?.website,
  };
  const filteredContactInfoFields = Object.entries(contactInfoFields).filter((entry) => entry[1]);

  /* 
Indicate mobile service & Stype (Only or both)
Socials
Share
Announcement (if any) similar to about us (title and content)
*/

  const parseSocialLink = (link) => {
    console.log(link);
    if (!link) return link;
    if (link[0] === '@') return `https://www.instagram.com/${link.slice(1, link.length)}`;
    return '//' + link;
  };
  return (
    <section className={classes.businessInfoRoot}>
      <div className={classes.businessHeroImgContainer}>
        <img src={businessHeroImage} alt={`${data?.name} hero`} />
      </div>
      <div className={classes.businessDetailsContainer}>
        <img className={classes.logoImg} src={businessLogo} alt={`${data?.name} logo`} />
        <div className={classes.businessDetails}>
          <Typography className={classes.businessName} variant="h1">
            {data?.name}
          </Typography>
          <span className={classes.contactInfo}>
            {filteredContactInfoFields.map(([linkPrefix, field], index) => {
              const isLastEntry = index === filteredContactInfoFields.length - 1;
              const link = linkPrefix === 'http' ? `https://${field}` : `${linkPrefix}:${field}`;
              /* Only need new tab (_blank) for http links */
              return (
                <Link className={classes.infoLinks} href={link} target="_blank" rel="noreferrer">
                  <Typography>{linkPrefix === 'tel' ? formatPhoneNumber(field) : field}</Typography>
                  {!isLastEntry && (
                    <Typography className={classes.hideInMobile} style={{ marginLeft: '0.5rem' }}>{`|`}</Typography>
                  )}
                </Link>
              );
            })}
          </span>

          {businessAddress}

          <span className={classes.detailsActionButtons}>
            {/* Move into its own component & DRY the icons rendering - use iterate on object pattern */}
            {data?.website && (
              <Tooltip title="Website">
                <Link href={parseSocialLink(data?.website)} target="_blank" rel="noreferrer">
                  <PublicIcon className={classes.socialIcon} />
                </Link>
              </Tooltip>
            )}
            {data?.instagram && (
              <Tooltip title="Instagram">
                <Link href={parseSocialLink(data?.instagram)} target="_blank" rel="noreferrer">
                  <InstagramIcon className={classes.socialIcon} />
                </Link>
              </Tooltip>
            )}
            {data?.facebook && (
              <Tooltip title="Facebook">
                <Link href={parseSocialLink(data?.facebook)} target="_blank" rel="noreferrer">
                  <FacebookIcon className={classes.socialIcon} />
                </Link>
              </Tooltip>
            )}
            {/*   <Tooltip title="Twitter">
              <Link href={data?.twitter} target="_blank" rel="noreferrer">
                <TwitterIcon className={classes.socialIcon} />
              </Link>
            </Tooltip> */}
            {/*    <Tooltip title="Snapchat">
              <Link href={data?.snapchat} target="_blank" rel="noreferrer">
                <SnapChatIcon className={classes.socialIcon} />
              </Link>
            </Tooltip> */}

            {data?.address.street && (
              <Tooltip title="Directions">
                <Link
                  href={
                    '//www.google.com/maps/place/' +
                    data?.address.street +
                    '+' +
                    data?.address.city +
                    '+' +
                    data?.address.province
                  }
                  target="_blank"
                  rel="noreferrer"
                >
                  <MapIcon className={classes.socialIcon} />
                </Link>
              </Tooltip>
            )}

            <Tooltip title="Share this page">
              <CopyToClipboard text={window.location.href} className={classes.socialIcon}>
                <ShareIcon />
              </CopyToClipboard>
            </Tooltip>
          </span>
        </div>
      </div>
    </section>
  );
}
