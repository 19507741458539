import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
// import Typography from '@material-ui/core/Typography';
import { NAV_OPTIONS } from '../utils/constants';
// import { hairStoreData } from "../utils/example-data";
import { Link } from 'react-router-dom';
import { useStateValue } from '../state';
import { navbarTabLink } from '../utils/helpers';

function LinkBar({ landing }) {
  const useStyles = makeStyles((theme) => ({
    // navContainer: {
    //   display: 'flex',
    //   justifyContent: 'center',
    //   alignItems: 'center',
    //   // For landing view, this component is shifted up to cover the landing hero image
    //   transform: landing? 'translateY(-50%)': null,
    //         color: theme.palette.common.white,
    //   padding: theme.spacing(2,3,2,3),
    //   [theme.breakpoints.down("md")]: {
    //     padding: theme.spacing(2,2,2,2),
    //   },
    //   [theme.breakpoints.down("sm")]: {
    //     padding: theme.spacing(2,1,2,1),
    //   },
    //   width: '100%',
    //   backgroundColor: theme.palette.common.black,
    // },

    breadcrumbs: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.font,
      transform: landing ? 'translateY(-10%)' : null,
      padding: theme.spacing(2, 4, 2, 4),
      display: 'flex',
      justifyContent: 'center',
      fontFamily: 'Arial',
      [theme.breakpoints.down('md')]: {
        display: 'none',
      },
    },
    link: {
      color: theme.palette.common.font,
      fontWeight: 400,
      fontSize: '1rem',
      letterSpacing: '0.05rem',
      textTransform: 'uppercase',
      '&:hover': {
        color: theme.palette.common.characters,
        textDecoration: 'none',
        cursor: 'pointer',
      },
      // margin: theme.spacing(0, 0.85, 0, 0.85),
      [theme.breakpoints.down('sm')]: {
        margin: theme.spacing(0, 0.5, 0, 0.5),
      },
      [theme.breakpoints.down('xs')]: {
        margin: theme.spacing(0, 0.25, 0, 0.25),
      },
    },

    linkCurrentCategory: {
      color: theme.palette.primary.main,
    },
  }));

  const classes = useStyles();
  const [{ location }] = useStateValue();
  //
  const category = window.location.href.split('/')[3];
  // console.log('category', category);
  return (
    <div style={{ width: '1245px', maxWidth: '1245px' }}>
      <Breadcrumbs aria-label="breadcrumb" separator="|" maxItems={20} className={classes.breadcrumbs}>
        {NAV_OPTIONS.map((item, index) => {
          return (
            <Link
              key={index}
              to={navbarTabLink(item.path, location.province, location.city)}
              className={`${classes.link} ${
                item.name.toLowerCase() === category.toLowerCase() ? classes.linkCurrentCategory : ''
              }`}
              variant="h6"
              style={{ textDecoration: 'none' }}
            >
              {item.name}
            </Link>
          );
        })}
      </Breadcrumbs>
    </div>
  );
}

export default LinkBar;

//  {/*<div className={classes.navContainer}> */}
//   {/* <div className={classes.navLinks}> */}
// {/*</div> */}
// {NAV_OPTIONS.map((item, index) => {
//   return (
//     <React.Fragment key={index}>

//     <Link
//       to={item.path}
//       className={`${classes.link} ${item.name.toLowerCase() === category ? classes.linkCategory : ''}`}
//       variant='h6'
//     >
//       {item.name}
//     </Link>
//     {index !== NAV_OPTIONS.length -1 && <div className={classes.separator}>|</div>}
//     </React.Fragment>
//   )
// })}
// {/* </div> */}
