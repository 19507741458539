import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import Rating from '@material-ui/lab/Rating';
// import { FEATURED_TYPES, NAV_OPTIONS } from "../utils/constants";
import { Link } from 'react-router-dom';
// import { parseIdFromLink, abbreviate } from "../utils/helpers";
import { NAV_OPTIONS } from '../utils/constants';
import { determineMobileServiceType, handleImage, navbarTabLink, parseIdFromLink } from '../utils/helpers';
import logoPin from '../assets/icons/beautimap.jpg';
import EmojiTransportationIcon from '@material-ui/icons/EmojiTransportation';
// import DriveEtaIcon from '@material-ui/icons/DriveEta';
import AirportShuttleIcon from '@material-ui/icons/AirportShuttle';

const useStyles = makeStyles((theme) => ({
  card: {
    width: 200,
    [theme.breakpoints.down('md')]: {
      width: 200,
    },
    display: 'relative',
    marginRight: '20px',
    marginTop: theme.spacing(2),
  },
  media: {
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    height: 200,
    paddingTop: 10,
    // border: '1px solid black',
    [theme.breakpoints.down('md')]: {
      height: 150,
      // border: '1px solid green'
    },
  },
  cardText: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    [theme.breakpoints.down('md')]: {
      fontSize: 'small',
    },
    color: '#EE333F',
  },
  cardTextTitle: {
    color: theme.palette.common.font,
    fontWeight: 'bold',
    [theme.breakpoints.down('md')]: {
      fontSize: '95%',
    },
  },
  rating: {
    display: 'flex',
    alignItems: 'center',
  },
  stars: {
    [theme.breakpoints.down('md')]: {
      fontSize: 'small',
    },
  },
  cardActionArea: {
    position: 'relative',
  },
}));
function FeaturedListItem(props) {
  const { item, type, location, mobile } = props;
  if (item.name.length > 16) {
    item.name = item.name.slice(0, 14) + '...';
  }
  const classes = useStyles();

  const itemPath = `${navbarTabLink(
    NAV_OPTIONS.find((option) => option.name === type).path,
    location.province,
    location.city
  )}/${parseIdFromLink(item._links.self.href, 0, '{?projection}')}/${item.slug}`;

  //Display street address if it exists, other wise display city, province
  const addressToDisplay =
    item.address && item.address.street ? item.address.street : `${item.address.city}, ${item.address.province}`;

  let addressLogoComponent = (
    <img
      src={logoPin}
      alt={`beautimap-logo`}
      style={{ height: '15px', marginRight: '10px' }}
      className={classes.appLogo}
    />
  );
  const mobileServiceType = determineMobileServiceType(item);
  if (mobileServiceType === 'mobileOnly')
    addressLogoComponent = <AirportShuttleIcon style={{ height: '15px', marginRight: '2px' }} />;
  if (mobileServiceType === 'storeWithMobileService')
    addressLogoComponent = <EmojiTransportationIcon style={{ height: '15px', marginRight: '2px' }} />;

  return (
    <Card
      className={classes.card}
      component={Link}
      to={itemPath}
      style={{
        color: '#000000',
        textDecoration: 'none',
        boxShadow: '0 4px 8px 0 rgba(0,0,0,0.1)',
        transition: ' 0.3s',
        borderRadius: '5px',
      }}
    >
      <CardActionArea className={classes.cardActionArea}>
        <CardMedia
          className={classes.media}
          image={handleImage(item.storeImage, { placeholderType: 'storeLogo' })}
          title={item.name}
        />
        <CardContent className={classes.cardDetails} style={{ paddingTop: 2 }}>
          <Typography noWrap variant="h6" className={classes.cardTextTitle}>
            {item.name}
          </Typography>
          <div
            style={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              width: '11rem',
            }}
          >
            <Typography noWrap variant="body1" color="secondary" className={classes.cardText}>
              {item.address && addressLogoComponent}
              {item.address && mobile && item.address.street.length > 12
                ? `${addressToDisplay.slice(0, 9)}...`
                : `${addressToDisplay}`}
            </Typography>
          </div>
          <div className={classes.rating}>
            <Rating
              name="read-only"
              value={item.avgRating ? Number(item.avgRating) : 0}
              precision={0.5}
              size="small"
              className={classes.stars}
              readOnly
            />
            <Typography variant="caption" className={classes.ratingText}>
              {`${item.avgRating ? Number(item.avgRating.toFixed(1)) : 0} (${
                item.numOfReviews ? item.numOfReviews : 0
              })`}
            </Typography>
          </div>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

export default FeaturedListItem;
