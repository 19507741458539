import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import Paper from '@material-ui/core/Paper';
import { makeStyles, Typography, IconButton, Button, Box } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import { ReactSortable } from 'react-sortablejs';
import $ from 'jquery';

const useStyles = makeStyles((theme) => ({
  thumbsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    flexWrap: 'wrap',
    marginTop: 10,
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center',
      width: '95%',
    },
  },
  cancelContainer: {
    [theme.breakpoints.down('sm')]: {
      width: '5%',
    },
    width: '0%',
  },
  thumb: {
    position: 'relative',
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 2,
    marginRight: 35,
    marginBottom: 35,
    [theme.breakpoints.down('md')]: {
      marginRight: 0,
    },
    width: '100%',
    height: '100%',
    maxHeight: 400,
    maxWidth: 400,
    padding: 4,
    boxSizing: 'border-box',
    // [theme.breakpoints.down("sm")]: {
    //   maxHeight: 200,
    //   maxWidth: 200,
    // },
    [theme.breakpoints.down('sm')]: {
      width: 50,
    },
  },
  thumbInner: {
    display: 'flex',
    minWidth: 0,
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    [theme.breakpoints.down('sm')]: {
      width: 50,
    },
  },
  img: {
    // display: "block",
    width: '100%',
    height: '100%',
    maxHeight: 150,
    maxWidth: 150,
    objectFit: 'contain',
    [theme.breakpoints.down('sm')]: {
      width: 50,
    },
  },
  paper: {
    padding: theme.spacing(2),
  },
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: 'dashed 3px #D1D1D1',
    borderRadius: 10,
    margin: theme.spacing(2),
  },
  wrapperError: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: `dashed 3px ${theme.palette.error.main}`,
    borderRadius: 10,
    margin: theme.spacing(2),
  },
  dropzoneText: {
    margin: theme.spacing(6),
    color: '#3b3b3b',
    width: '60%',
  },
  dropzoneTextError: {
    margin: theme.spacing(6),
    color: '#5b5b5b',
    width: '60%',
  },
  dropzoneRejectedError: {
    margin: theme.spacing(6),
    color: theme.palette.error.main,
    width: '60%',
  },
  previewWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  cancelIcon: {
    position: 'absolute',
    top: -24,
    right: -22,
    opacity: 1,
    color: theme.palette.error.main,
    [theme.breakpoints.down('sm')]: {},
  },
  // cancelIconMobile: {
  // 	opacity: 1,
  // 	color: theme.palette.error.main,
  // 	[theme.breakpoints.up("md")]: {
  // 	},
  // },
  cancelButtonContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: theme.spacing(-1, 0, 0, -1.5),
    height: 201,
    width: '100%',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  number: {
    position: 'absolute',
    top: -13,
    left: -20,
    backgroundColor: '#ebebeb',
    padding: theme.spacing(0, 1.5, 0, 1.5),
    borderRadius: '10px',
    color: theme.palette.common.black,
  },
  error: {
    color: theme.palette.error.main,
  },
  placeholderText: { margin: theme.spacing(1, 0, 1, 2) },
  placeholderButton: {
    margin: theme.spacing(1, 0, 1, 2),
  },
}));

function ImageUploader(props) {
  const { files, setFiles, error, multi, disabled, errorText, imageType } = props;

  // const files = [{preview: "https://sdaw-dev-bucket.s3.ca-central-1.amazonaws.com/files/blob-1594226970613."},{preview: "https://sdaw-dev-bucket.s3.ca-central-1.amazonaws.com/files/blob-1594226970613."}];
  // const setFiles = () => {};
  // const error = false;
  // const multi = true;
  // const disabled = false;

  const classes = useStyles();

  const [fileError, setFileError] = useState(false);

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    maxSize: 10485760,
    onDrop: (acceptedFiles, fileRejections) => {
      setFileError(false);
      if (multi) {
        setFiles(
          [
            ...files,
            ...acceptedFiles.map((file) =>
              Object.assign(file, {
                preview: URL.createObjectURL(file),
              })
            ),
          ].slice(0, 12)
        );
      } else {
        if (fileRejections.length > 0) {
          setFileError(true);
        } else {
          setFiles(
            acceptedFiles.slice(0, 1).map((file) =>
              Object.assign(file, {
                preview: URL.createObjectURL(file),
              })
            )
          );
        }
      }
    },
  });

  const thumbs = files
    // .filter((file, i) => (!multi && !i) || multi)
    .map((file, i) => (
      <div key={i}>
        <div className={classes.thumb}>
          <div className={classes.thumbInner}>
            {file.preview && <img src={file.preview} className={classes.img} alt="avatar preview" />}
            {!file.preview && (
              <Button
                style={{
                  backgroundColor: '#FFB4A4',
                  color: 'white',
                  height: '2rem',
                  width: '5rem',
                }}
                onClick={(e) =>
                  setFiles(
                    files.filter((file, j) => {
                      if (i === j) {
                        URL.revokeObjectURL(file.preview);
                      }
                      return i !== j;
                    })
                  )
                }
              >
                Delete
              </Button>
            )}
          </div>
          {multi && (
            <Box color="secondary" aria-label={`image-${i}`} className={classes.number}>
              <strong>{i + 1}</strong>
            </Box>
          )}
          {
            <IconButton
              disabled={disabled}
              color="secondary"
              aria-label="remove-image-1"
              className={classes.cancelIcon}
              onClick={(e) =>
                setFiles(
                  files.filter((file, j) => {
                    if (i === j) {
                      URL.revokeObjectURL(file.preview);
                    }
                    return i !== j;
                  })
                )
              }
            >
              <CancelIcon />
            </IconButton>
          }
        </div>
      </div>
    ));

  // useEffect(
  //   () => () => {
  //     // Make sure to revoke the data uris to avoid memory leaks
  //     files.forEach((file) => URL.revokeObjectURL(file.preview));
  //   },
  //   [files]
  // );

  return (
    <Paper
      elevation={2}
      className={classes.paper}
      onDragCapture={() => {
        $('#nav-bar-client-admin').hide();
      }}
      onDropCapture={() => {
        $('#nav-bar-client-admin').show();
      }}
      onDragOver={(ev) => {
        ev.preventDefault();
      }}
    >
      <section className="container">
        {/* {!files.length && !hideAddPlaceholder && (
					<Fragment>
						<Typography className={classes.placeholderText}>
							Don't have an image ready just yet? Use our placeholder for now.
						</Typography>
						<Button
							variant="outlined"
							className={classes.placeholderButton}
							onClick={() => {
								axios
									.get(placeholderPath, {
										responseType: "arraybuffer",
										headers: {
											Accept: "image/*",
										},
									})
									.then((response) => {
										const blob = new Blob([response.data], {
											type: "image/jpeg",
										});
										setFileError(false);
										setFiles([
											Object.assign(blob, {
												url: URL.createObjectURL(blob),
											}),
										]);
									});
							}}
							disabled={disabled}
						>
							Use Placeholder Image
						</Button>
					</Fragment>
				)} */}
        {error && (
          <Typography variant="body2" className={classes.error}>
            {errorText}
          </Typography>
        )}
        {files.length < 12 && imageType !== 'banner' && !disabled && (
          <div
            {...getRootProps({ className: 'dropzone' })}
            className={error || fileError ? classes.wrapperError : classes.wrapper}
          >
            <input {...getInputProps()} />
            <Typography
              variant="body2"
              align="center"
              className={error || fileError ? classes.dropzoneRejectedError : classes.dropzoneText}
            >
              Drag 'n' drop your image here, or click to select a file. Max image size is 10 MB.{' '}
              {multi
                ? "You can only upload a maximum of 12 images. Drag images around to set the order of images as you'd like."
                : ''}
            </Typography>
          </div>
        )}
        {files.length >= 12 && !disabled && (
          <div className={error ? classes.wrapperError : classes.wrapper}>
            <input {...getInputProps()} />
            <Typography variant="body2" align="center" className={classes.dropzoneTextError}>
              {`Maximum number of images (12) reached.`}
            </Typography>
          </div>
        )}
        {disabled && (
          <div className={error ? classes.wrapperError : classes.wrapper}>
            <input {...getInputProps()} />
            <Typography variant="body2" align="center" className={classes.dropzoneTextError}>
              {`When using a brand's product, the images are selected by the brand`}
            </Typography>
          </div>
        )}
        <div className={classes.previewWrapper}>
          {files.length > 0 && <Typography variant="h5">Preview</Typography>}
          {disabled && (
            <div className={classes.thumbsContainer} style={{ height: '100%', width: '100%' }}>
              {thumbs}
            </div>
          )}
          {!disabled && (
            <div style={{ display: 'flex' }}>
              <ReactSortable
                list={files}
                setList={setFiles}
                animation={150}
                group="setting-pinned"
                style={{ height: '100%', width: '100%' }}
                className={classes.thumbsContainer}
              >
                {thumbs}
              </ReactSortable>
              <div className={classes.cancelContainer}>
                {/* {files.map((file, i) => (
									<div className={classes.cancelButtonContainer} key={i}>
										<IconButton
											disabled={disabled}
											color="secondary"
											aria-label="remove-image-2"
											className={classes.cancelIconMobile}
											onClick={(e) =>
												setFiles(
													files.filter((file, j) => {
														if (i === j) {
															URL.revokeObjectURL(file.preview);
														}
														return i !== j;
													})
												)
											}
										>
											<CancelIcon />
										</IconButton>
									</div>
								))} */}
              </div>
            </div>
          )}
        </div>
      </section>
    </Paper>
  );
}

export default ImageUploader;
